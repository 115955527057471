const initialState = {
  tourLink: undefined,
};

const setTourLink = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOUR_LINK":
      console.log("tourlink");
      console.log(action.payload);
      return { ...state, tourLink: action.payload };
    default:
      return state;
  }
};

export default setTourLink;
