import React, { Component } from "react";
import { connect } from "react-redux";
import { setTourLink } from "../actions";

import videoLinks from "../data/videoLinksData";
import videoTourText from "../data/videoTourTextData";

export class CategoryVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoTourClicked: 1,
    };
  }

  buttonVideoCarouselClicked = (direction) => {
    let currentVideoCarouselState = this.state.videoTourClicked;
    if (direction === -1 && currentVideoCarouselState !== 2) {
      this.setState({
        videoTourClicked: currentVideoCarouselState + 1,
      });
    } else if (direction === 1 && currentVideoCarouselState !== 0) {
      this.setState({
        videoTourClicked: currentVideoCarouselState - 1,
      });
    }
  };

  render() {
    if (this.props.contentType === "video")
      return (
        <div className="window-content window-content-video">
          <div className="videos-container">
            <div className="carousel-buttons">
              <div
                className="btn btn-left"
                style={{ opacity: this.state.videoTourClicked === 0 ? 0.3 : 1 }}
                onClick={() => {
                  this.buttonVideoCarouselClicked(1);
                }}
              ></div>
              <div
                className="btn btn-right"
                style={{ opacity: this.state.videoTourClicked === 2 ? 0.3 : 1 }}
                onClick={() => {
                  this.buttonVideoCarouselClicked(-1);
                }}
              ></div>
            </div>
            <div className="overflow-wrapper">
              <div className="video-carousel" style={{ transform: `translate(${-50 + ((1 - this.state.videoTourClicked) * 100) / 3}%,-50%)` }}>
                {videoLinks.map((videoLink, index) => (
                  <div className="btn btn-video" key={index} onClick={() => this.props.setTourLink(videoLink[this.props.languageIndex])}>
                    <div className="icon"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="text-container">
            <div className="title">{videoTourText[this.state.videoTourClicked][0][this.props.languageIndex]}</div>
            <div className="description">{videoTourText[this.state.videoTourClicked][1][this.props.languageIndex]}</div>
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex,
    categoriesContentIndex: state.setClickedContent.categoriesContentIndex,
    contentType: state.setClickedContent.contentType,
  };
};

const mapDispatchToProps = {
  setTourLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryVideo);
